export const ClientOnlyScript = ({ dangerouslySetInnerHTML, }) => {
    const html = dangerouslySetInnerHTML?.__html;
    if (!html)
        return null;
    return (<script ref={(ref) => {
            if (!ref)
                return;
            ref.innerHTML = html;
        }}/>);
};
