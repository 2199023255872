import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
// We need to do this because the appConfig is not available at build time
let globalMixPanelToken;
const appConfig = global?.appConfig;
// When mixpanel token is available, initialize mixpanel
if (appConfig && appConfig.mixpanelToken) {
    globalMixPanelToken = appConfig.mixpanelToken;
    mixpanel.init(globalMixPanelToken, {
        api_host: appConfig.mixpanelApiHost || 'https://api.mixpanel.com',
    });
}
let actions = {
    // Identify a user with a unique ID
    identify: (id) => {
        if (globalMixPanelToken)
            mixpanel.identify(id);
    },
    // Remap one ID to another
    alias: (newId, existingId) => {
        if (globalMixPanelToken)
            mixpanel.alias(newId, existingId);
    },
    // Set properties on a user record only if they do not yet exist
    setPersonProperties: (properties) => {
        if (globalMixPanelToken)
            mixpanel.people.set_once(properties);
    },
    // Update user properties
    updateUser: (properties) => {
        if (globalMixPanelToken)
            mixpanel.people.set(properties);
    },
    // General Mixpanel Event Tracking Function
    track: (eventName, properties, callback) => {
        if (globalMixPanelToken) {
            mixpanel.track(eventName, {
                'User ID': global.appConfig.userId,
                'Creator ID': global.appConfig.creatorId,
                Role: global.appConfig.role,
                ...properties,
            }, callback);
        }
        else {
            callback?.();
        }
    },
    linkClicked: (event, linkUrl, linkText, navigate, extraProperties = {}) => {
        event.preventDefault();
        TheLeapAnalytics.track('Link Clicked', {
            'User ID': global.appConfig.userId,
            'Creator ID': global.appConfig.creatorId,
            Role: global.appConfig.role,
            ...(linkText && { 'Link Text': linkText }),
            URL: linkUrl,
            ...extraProperties,
        }, () => {
            if (navigate) {
                navigate(linkUrl);
                return;
            }
            const linkElement = event.target.getAttribute('target')
                ? event.target
                : event.target.parentElement;
            if (linkElement) {
                const referrerPolicy = linkElement.rel === 'noreferrer' ? 'no-referrer' : undefined;
                window.open(linkUrl, linkElement.target, referrerPolicy);
            }
        });
    },
    buttonClicked: (buttonAction, buttonText, extraProperties = {}) => {
        TheLeapAnalytics.track('Button Clicked', {
            ...(buttonText && { 'Button Text': buttonText }),
            'Button Action': buttonAction,
            ...extraProperties,
        });
    },
    toggleClicked: (toggleAction, toggleText) => {
        TheLeapAnalytics.track('Toggle Clicked', {
            ...(toggleText && { 'Button Text': toggleText }),
            'Button Action': toggleAction,
        });
    },
    modalViewed: (name) => {
        TheLeapAnalytics.track('Modal Viewed', { Name: name });
    },
    formSubmitted: (formId, extraProperties = {}) => {
        TheLeapAnalytics.track('Form Submitted', {
            'Form ID': formId,
            ...extraProperties,
        });
    },
};
export const TheLeapAnalytics = actions;
export const useTheLeapAnalytics = ({ userId, creatorId, handle, email, role, appConfig, }) => {
    useEffect(() => {
        mixpanel.init(appConfig.mixpanelToken);
    }, [appConfig]);
    // Identify the creator in Mixpanel
    useEffect(() => {
        if (userId && creatorId && email && handle && role) {
            TheLeapAnalytics.identify(appConfig.mixpanelDevEnvDistinctId ?? userId);
            TheLeapAnalytics.setPersonProperties({
                'User ID': userId,
                'Creator ID': creatorId,
                Email: email,
                Handle: handle,
                Role: role,
            });
        }
    }, [userId, creatorId, handle, email, role, appConfig]);
    global.appConfig = appConfig;
};
export default TheLeapAnalytics;
