import { useEffect } from 'react';
export const useLuckyOrange = ({ userId, handle, email, }) => {
    // Identify the creator in Lucky Orange
    useEffect(() => {
        if (userId && email && handle) {
            // Add Lucky Orange tracking
            window.LOQ = window.LOQ || [];
            window.LOQ.push([
                'ready',
                async (LO) => {
                    await LO.$internal.ready('visitor');
                    LO.visitor.identify(userId, { email: email, name: handle });
                },
            ]);
        }
    }, [userId, handle, email]);
};
export const LuckyOrange = ({ luckyOrangeId }) => {
    if (!luckyOrangeId)
        return null;
    return (<>
      {luckyOrangeId && (<script async defer src={`https://tools.luckyorange.com/core/lo.js?site-id=${luckyOrangeId}`}/>)}
    </>);
};
export default LuckyOrange;
