// Why this hook? Ref:
// https://github.com/facebook/react/issues/14099#issuecomment-440013892
// https://github.com/ueberdosis/tiptap/issues/2403
import { useMemo, useRef } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';
export const useEventCallback = (fn) => {
    let ref = useRef(fn);
    useIsomorphicLayoutEffect(() => {
        ref.current = fn;
    });
    return useMemo(() => (...args) => {
        const { current } = ref;
        return current(...args);
    }, []);
};
